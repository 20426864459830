<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>编号：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.userId">
        </li>
        <li>
          <p>账号：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.userName">
        </li>
        <li>
          <p>姓名：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.xingming">
        </li>
        <li>
          <p>类型：</p>
          <select @change="getData(1)" v-model="form.parentId">
            <option :value="1">主账户</option>
            <option :value="0">全部</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td>编号</td>
            <td>登录账号</td>
            <td>电话</td>
            <td>注册时间</td>
            <td>店铺数上限</td>
            <td>员工数上限</td>
            <td>账号类别</td>
            <td>账号级别</td>
            <td>账号状态</td>
            <td>姓名</td>
            <td>性别</td>
            <td>门店</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{item.id}}</td>
            <td>{{item.userName}}</td>
            <td>{{item.phone}}</td>
            <td>{{getShengri(item.zhuceriqi)}}</td>
            <td>{{item.maxStore}}</td>
            <td>{{item.maxEmp>=50?'不限':item.maxEmp}}</td>
            <td>{{countAdmin(item.admin)}}</td>
            <td>{{item.parentId==0?'主账号':'普通账号'}}</td>
            <td><span :class="item.state==1?'suc':'err'">{{item.state==1?"正常":"删除"}}</span></td>
            <td>{{item.xingming}}</td>
            <td>{{item.xingbie}}</td>
            <td>{{item.fenpeimendianName}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="setInfoData(item)">管理</button>
                <button type="button" class="new" @click="toAllMendian(item.id)">门店</button>
                <button type="button" class="pur" @click="resetPassword(item.id,item.xingming)">重置密码</button>
                <button type="button" class="ora" v-if="item.admin==2" @click="kaitong(item)">正式开通</button>
                <button v-if="item.state==1" type="button" class="err" @click="delData(item.id,item.xingming)">删除</button>
                <button v-else type="button" class="gre" @click="restoreData(item.id,item.xingming)">恢复</button>
                <button type="button" v-if="item.parentId==0" class="err" @click="clearAllDataByUser(item.id,item.xingming)">彻底清空</button>
                <button type="button" class="ora" @click="banNow(item.id,item.xingming)">立即封禁</button>
                <button type="button" class="suc" @click="delBanNow(item.id,item.xingming)">解封</button>
              </div>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'packageSettings',
  data() {
    return {
      infoShow: false,
      title:'',
      dataList:[],
      form:{
        userId:null,
        userName:'',
        xingming:'',
        parentId:1,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
    if(this.$route.query.id) this.form.userId=parseInt(this.$route.query.id)
    this.getData(1)
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    delUserNow(id,name){
      this.$trueOrFalse({
        title: '删除提示',
        content: `确认立即彻底删除 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/manage/delUserNow',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    banNow(id,name){
      this.$trueOrFalse({
        title: '封禁提示',
        content: `确认立即封禁 ${name} ?`,
        torf: false,
        suc:()=>{
          axios.post('/emp/banNow',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    delBanNow(id,name){
      axios.post('/emp/delBanNow',{id,name}).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: res.msg})
          this.getData(1)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    toAllMendian(id){
      this.$router.push({path:"/allMendian",query:{id}})
    },
    getShengri(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD")
      else return ''
    },
    delData(id,name){
      this.$trueOrFalse({
        title: '重要提示',
        content: `确认删除账号： ${name} 下所有账号?`,
        torf: false,
        suc:()=>{
          axios.post('/manage/delUser',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    clearAllDataByUser(id,xingming){
      this.$trueOrFalse({
        title: '重要提示',
        content: `确认彻底删除账号： ${xingming} 及其名下所有数据?`,
        torf: false,
        suc:()=>{
          axios.post('/manage/clearAllDataByUser',{id,xingming}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    restoreData(id,name){
      this.$trueOrFalse({
        title: '重要提示',
        content: `确认恢复账号： ${name} ?`,
        torf: true,
        suc:()=>{
          axios.post('/manage/restoreUser',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    resetPassword(id,name){
      this.$trueOrFalse({
        title: '重要提示',
        content: `确认重置 ${name} 的密码为默认密码 123456 ?`,
        torf: false,
        suc:()=>{
          axios.post('/manage/resetPass',{id,name}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    kaitong(item){
       this.$trueOrFalse({
        title: '提示',
        content: `确认正式开通账号${item.xingming}？`,
        torf: true,
        suc:()=>{
          axios.post('/manage/kaitong',{id:item.id,xingming:item.xingming}).then((res) => {
            if(res.code===5000){
              this.$Mesg({content: res.msg})
              this.getData(1)
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        },
      })
    },
    setInfoData(item){
      this.title=item.xingming
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/manage/allUserListPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    countAdmin(admin){
      switch (admin){
        case 0:{
          return '正式账号'
        }
        case 1:{
          return '正式账号'
        }
        case 2:{
          return '正式账号'
        }
        case 3:{
          return '正式账号'
        }
        case 4:{
          return '正式账号'
        }
        case 9:{
          return '超管账号'
        }
        default:{
          return ''
        }
        
      }
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
  },
}
</script>
