<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span>用户账号：</span>{{form.userName}}</li>
            <li class="up"><span>用户密码：</span>{{form.passWord}}</li>
            <li class="up"><span class="import">店铺上限：</span><input type="number" v-model.number="form.maxStore"/></li>
            <li class="up"><span class="import">员工上限：</span><input type="number" v-model.number="form.maxEmp"></li>
            <li class="up"><span>账号性质：</span>
              <select v-model="form.admin">
                <option :value="0">正常用户</option>
                <option :value="9">超级管理员</option>
              </select>
            </li>
            <li class="up"><span>所属门店：</span>{{form.fenpeimendianName}}</li>
            <li class="up"><span>用户姓名：</span>{{form.xingming}}</li>
            <li class="up"><span>用户性别：</span>{{form.xingbie}}</li>
            <li class="up"><span>提示信息：</span>请慎重修改账号属性</li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {deepCopy,testStr} from '@/utils/util'
// import moment from 'moment'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        this.form=deepCopy(newD.data)
      },
      deep:true
    }
  },
  data() {
    return {
      form:{},
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    init(){
      this.form=deepCopy(this.MmsData.data)
    },

    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      if(testStr(this.form.id)){
        this.$Mesg({
          content: '账号不能为空',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.maxStore)){
        this.$Mesg({
          content: '门店上限不正确',
          errOrSuc: 'war',
        })
        return
      }
      if(testStr(this.form.maxEmp)){
        this.$Mesg({
          content: '员工上限不正确',
          errOrSuc: 'war',
        })
        return
      }
      axios.post('/manage/allUserEdit',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: '修改账户成功'})
          this.$parent.getData(1)
          this.closedWindow()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
